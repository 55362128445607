<template>
  <Disclosure as="nav" v-slot="{ open }">
    <div
      class="max-w-full mx-auto lg:border-b-grey-2 lg:border-b relative z-50"
      :class="menuClass"
    >
      <div class="flex justify-between text-grey-5 font-semibold text-sm xl:text-base constrain">
        <!-- Logo -->
        <div class="flex items-center">
          <NuxtLink to="/">
            <CommonSVGTemplate
              @click="closeMenu"
              :class="logoClass"
              width="185"
              height="44"
              :alt="menuOpen ? 'JUSTHIRE white logo' : 'JUSTHIRE logo'"
              :path="logoPath"
            />
          </NuxtLink>
        </div>

        <!-- Desktop Menu -->
        <div class="hidden lg:flex lg:ml-6 gap-6 xl:gap-12">
          <NuxtLink
            v-for="item in menuItems"
            :key="item.name"
            :to="item.link"
            class="inline-flex items-center py-[25px] border-b-2 border-b-transparent hover:text-primary-pressed hover:border-primary-pressed"
          >
            {{ item.name }}
          </NuxtLink>
        </div>

        <!-- Book a Skip Button -->
        <div class="hidden lg:flex items-center">
          <NuxtLink to="/booking/location">
            <Button intent="primary-outline">Book a skip</Button>
          </NuxtLink>
        </div>

        <!-- Hamburger Menu -->
        <div class="flex items-center lg:hidden">
          <DisclosureButton class="inline-flex items-center justify-center py-2 rounded-md text-base-black focus:outline-none" aria-label="Toggle menu">
            <span class="sr-only">Open main menu</span>
            <component :is="menuOpen ? XMarkIcon : Bars3Icon"
                       @click="toggleMenu"
                       class="block h-10 w-10"
                       :class="menuOpen ? 'text-white' : ''"
                       aria-hidden="true"
            />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <!-- Mobile Menu -->
    <DisclosurePanel v-show="menuOpen" static class="lg:hidden fixed inset-0 mt-[55px] z-50 bg-white w-full">
      <CommonSVGTemplate
        alt="Gradient"
        width="100%"
        height="100%"
        :path="blueCircle"
        class="w-full h-full fixed z-10"
        loading="lazy"
      />

      <!-- Scrollable Container -->
      <div class="absolute z-20 w-full px-4 pt-2 pb-40 space-y-1 overflow-y-auto h-[calc(100vh-55px)]">
        <span
          v-for="item in menuItems"
          :key="item.name"
          @click="handleMenuItemClick(item.link)"
          class="block border-b border-blue-lightest pr-4 py-6 text-base font-medium text-neutrals-800 leading-28"
        >
          {{ item.name }}
        </span>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { ProjectLogo, WhiteLogo, BlueCircle } from "~/constants/AssetPaths";
import { useEventListener } from '@vueuse/core';

// Refs and router
const router = useRouter();
const menuOpen = ref(false);
const projectLogo = ref(ProjectLogo);
const whiteLogo = ref(WhiteLogo);
const blueCircle = ref(BlueCircle);

// Menu items
const menuItems = ref([
  { name: 'Booking', link: '/booking/location' },
  { name: 'About us', link: '/about-us' },
  { name: 'Skip Hire', link: '/c/waste/skip-hire' },
  { name: 'Knowledge base', link: '/knowledge' },
  { name: 'Locations', link: '/locations' },
  { name: 'Contact', link: '/contact-us' },
]);

// Computed properties
const logoPath = computed(() => (menuOpen.value ? whiteLogo.value : projectLogo.value));
const menuClass = computed(() => menuOpen.value ? 'bg-energy-green' : 'bg-white');
const logoClass = 'lg:w-[185px] w-[129px] lg:h-[44px] h-[31px]';

// Methods
function handleMenuItemClick(link) {
  closeMenu();
  if (link) {
    router.push(link);
  }
}

function toggleMenu() {
  menuOpen.value = !menuOpen.value;
  updateThemeColor(menuOpen.value);
}

function closeMenu() {
  menuOpen.value = false;
  updateThemeColor(false);
}

// Theme color update
const THEME_COLORS = {
  menuOpen: '#28C700',
  default: '#FFFFFF',
};

function updateThemeColor(isOpen) {
  useHead({
    meta: [
      {
        name: 'theme-color',
        content: isOpen ? THEME_COLORS.menuOpen : THEME_COLORS.default,
      },
    ],
  });
}

// Handle resize event for larger screens
function handleResize() {
  if (window.innerWidth >= 1024 && menuOpen.value) closeMenu();
}

// Setup event listeners
onMounted(() => {
  useEventListener(window, 'resize', handleResize);
});
</script>
